import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ProcessOfStartingACampaign = ({ location }) => {
  const title = "PARKLoT从申请到活动开始的流程"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="本节介绍启动即时赢的过程。 基本流程是：申请--数据准备、开票、付款--活动创建。 你需要准备的数据取决于活动的类型。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">开始运动的过程是什么？</h1>
            <p className="qa-page__answer">
              基本流程是：申请--数据准备和支付--活动创建--活动开始。
              <br />
            </p>
            <p className="qa-page__text">
              本页解释了如何启动基本计划（即时赢取活动）的步骤。
              关于组织非即赢活动的信息，如标签活动，
              <a
                className="qa-page__text-link"
                href="https://hub.park-lot.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                请通过联系表与我们联系。
              </a>
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">活动开始前的步骤</h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>
                      <a
                        className="qa-page__text-link"
                        href="/forms/apply"
                        target="_blank"
                        rel="noreferrer"
                      >
                        请使用申请表格进行申请。
                      </a>
                    </li>
                    <li>
                      请为该活动准备必要的信息和数据。
                      所需的六项信息和数据是：Twitter账户、Twitter开发者账户、活动名称、目前、活动期间和提交推文的图片。
                    </li>
                    <li>付款必须在活动开始前至少三个工作日进行。</li>
                    <li>
                      确认付款后，我们将处理你的Twitter账户，这样你就可以用PARKLoT登录了。
                    </li>
                    <li>请登录PARKLoT并创建一个活动。</li>
                    <li>
                      在活动发生时，会自动在推特上发布一条推文，启动活动。
                      抽奖是自动进行的，获奖者可以在管理屏幕上找到。
                    </li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  为什么我需要一个Twitter开发者账户？
                </h3>

                <p className="qa-page__text">
                  需要一个
                  <a
                    className="qa-page__text-link"
                    href="https://developer.twitter.com/en/apply-for-access"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter开发者账户
                  </a>
                  来检索转发用户并使用Twitter API自动发送直接信息。
                  我们要求你从主办该活动的推特账户中获得这一信息。
                </p>

                <p className="qa-page__text">
                  Twitter开发者账户从申请到接受需要3天到2周时间。
                  请务必在活动前两周申请。
                </p>

                <h3 className="qa-page__h3">
                  我不知道如何申请Twitter的开发者账户。
                </h3>

                <p className="qa-page__text">
                  我们可以代表你申请一个Twitter开发者账户。 请在申请时告诉我们。
                </p>

                <h3 className="qa-page__h3">
                  我能否以适当的方式准备竞选图像？
                </h3>

                <p className="qa-page__text">
                  参与者在活动的图像中看到什么，礼物是什么样子的？
                  如果我参加了，我会赢吗？ 在一瞬间。
                  因此，毫不夸张地说，你活动中的图像将决定你活动的成功。
                  我们建议你在图像上花费尽可能多的时间和预算。
                  我们还可以代表您创建活动图像。
                  如果你希望我们这样做，请在申请时告诉我们。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ProcessOfStartingACampaign
